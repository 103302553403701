/*
客製化通路商查詢

第一個產品分類採用hardcode鎖死

-新增口味的流程-
客戶如果新的口味新增時，要在後台建立分類『口味』
，然後前端的code也要做一個對應表更新($scope.productCategoryMap)

//step1: 會取得口味的所有資料
$scope.tasteDB = $window.TASTE;

//step2: 建立hardcode取得下拉式選單的分類
$scope.productCategoryMap

*/
angular.module('myApp.controllers')

    .controller('DealerControll', ['$rootScope', '$scope', 'avLog', '$window', 'API_EVENTS', 'SweetAlert', 'blockUI', '$translate', 'ServerConfig', 'ThemeConfig', 'CategoryService', 'Notification',
        function ($rootScope, $scope, avLog, $window, API_EVENTS, SweetAlert, blockUI, $translate, ServerConfig, ThemeConfig, CategoryService, Notification) {


            var logger = avLog.getLogger('DealerControll');
            logger.info('page loaded');

            //避免第一次整頁載入page loaded裡的初始化讀取二次
            $scope.waitingUpdateUserData = false;

            //查詢通路
            $scope.dealers = [];
            // $window.DEALERS;

            //下拉選項
            // $scope.taste = $window.TASTE;
            // $scope.capacity = $window.CAPACITY;
            //db的值
            $scope.tasteDB = $window.TASTE;
            $scope.taste = [];

            $scope.capacityDB = $window.CAPACITY;
            $scope.capacity = [];

            var allcities = $window.CITIES;
            allcities.unshift('網購平台');
            allcities.unshift('連鎖賣場');


            // ng-hide="city=='釣魚臺'||city=='南海島'" 
            for (var i = 0; i < allcities.length; i++) {

                if (allcities[i] === '釣魚臺' ||
                    allcities[i] === '南海島' ||
                    allcities[i] === '連江縣') {

                    allcities.splice(i, 1);
                }

                if(allcities[i].indexOf('臺')>=0){
                    allcities[i] = allcities[i].replace('臺','台');
                }

            }

            $scope.citiesDB = allcities;
            $scope.cities = [];

            //選擇記錄
            $scope.categoryId = 6; //預設為美食調理
            $scope.selectedTaste = null; //口味
            $scope.selectedCapacity = null; //容量
            $scope.selectedCity = null; //選擇的城市

            //因為這裡只需要中文
            //口味/容量過濾db(不支援後台，因為後台這樣要綁超多容量)
            var productCategoryMap = {
                //美食調理醬的id
                '6': [{
                        name: '巧克力糖漿',
                        capacity: [
                            '680g',
                            '2.5kg'
                        ]
                    },
                    {
                        name: '葡萄果粒奶酥抹醬',
                        capacity: [
                            '400g',
                            '720g'
                        ]
                    }, {
                        name: '蒜味十足',
                        capacity: [
                            '175g',
                            '450g'
                        ]
                    }, {
                        name: '蒜香抹醬',
                        capacity: [
                            '175g',
                            '450g'
                        ]
                    }, {
                        name: '健鈣塩味抹醬',
                        capacity: [
                            '400g',
                            '1.5kg'
                        ]
                    }, {
                        name: '芝麻香酥抹醬',
                        capacity: [
                            '450g',
                            '800g',
                            '1.8kg'
                        ]
                    }, {
                        name: '純奶酥抹醬',
                        capacity: [
                            '160g',
                            '380g',
                            '700g',
                            '1.6kg'
                        ]
                    },
                    {
                        name: '鹹奶酥抹醬',
                        capacity: [
                            '380g'
                        ]
                    },
                     {
                        name: '椰香奶酥抹醬',
                        capacity: [
                            '200g',
                            '450g',
                            '800g',
                            '1.8kg'
                        ]
                    }, {
                        name: '花生醬',
                        capacity: [
                            '900g',
                            '3kg'
                        ]
                    }, {
                        name: '巧克力醬',
                        capacity: [
                            '200g',
                            '500g',
                            '900g',
                            '3kg'
                        ]
                    }, {
                        name: 'A級巧克力醬',
                        capacity: [
                            '3kg'
                        ]
                    }

                ],
                //paste系列的id
                '7': [{
                        name: '椰香奶酥抹醬',
                        capacity: [
                            // '28g',
                            '175g',
                            '250g'
                        ]
                    }, {
                        name: '巧克力醬',
                        capacity: [
                            // '28g',
                            // '175g',
                            '250g'
                        ]
                    },
                    {
                        name: '花生醬',
                        capacity: [
                            // '28g',
                            '250g'
                        ]
                    }, {
                        name: '芝麻香酥抹醬',
                        capacity: [
                            // '28g',
                            // '175g',
                            '250g'
                        ]
                    },
                    {
                        name: '純奶酥抹醬',
                        capacity: [
                            // '28g',
                            '220g'

                        ]
                    },
                    {
                        name: '葡萄果粒奶酥抹醬',
                        capacity: [

                            '240g'

                        ]
                    },
                    {
                        name: '抹茶醬',
                        capacity: [

                            '250g'

                        ]
                    },
                    {
                        name: '鹹奶酥抹醬',
                        capacity: [
                            '220g'
                        ]
                    },
                    {
                        name: '厚奶香蒜抹醬',
                        capacity: [

                            '160g',
                            '450g',

                        ]
                    }
                    
                ]
            };

            //存放選擇的結果
            var filterTaste = [];
            var filterCapacity = [];
            var filterCities = [];

            /***
             *     /$$$$$$$$ /$$    /$$ /$$$$$$$$ /$$   /$$ /$$$$$$$$
             *    | $$_____/| $$   | $$| $$_____/| $$$ | $$|__  $$__/
             *    | $$      | $$   | $$| $$      | $$$$| $$   | $$   
             *    | $$$$$   |  $$ / $$/| $$$$$   | $$ $$ $$   | $$   
             *    | $$__/    \  $$ $$/ | $$__/   | $$  $$$$   | $$   
             *    | $$        \  $$$/  | $$      | $$\  $$$   | $$   
             *    | $$$$$$$$   \  $/   | $$$$$$$$| $$ \  $$   | $$   
             *    |________/    \_/    |________/|__/  \__/   |__/   
             *                                                       
             *                                                       
             *                                                       
             */


            $rootScope.$on(API_EVENTS.updateUserData, function (event, data) {
                logger.debug('API_EVENTS.updateUserData');
                $scope.waitingUpdateUserData = true;
                // $scope.init();
            });

            /***
             *      /$$$$$$  /$$$$$$$$ /$$       /$$$$$$$$  /$$$$$$  /$$$$$$$$
             *     /$$__  $$| $$_____/| $$      | $$_____/ /$$__  $$|__  $$__/
             *    | $$  \__/| $$      | $$      | $$      | $$  \__/   | $$   
             *    |  $$$$$$ | $$$$$   | $$      | $$$$$   | $$         | $$   
             *     \____  $$| $$__/   | $$      | $$__/   | $$         | $$   
             *     /$$  \ $$| $$      | $$      | $$      | $$    $$   | $$   
             *    |  $$$$$$/| $$$$$$$$| $$$$$$$$| $$$$$$$$|  $$$$$$/   | $$   
             *     \______/ |________/|________/|________/ \______/    |__/   
             *                                                                
             *                                                                
             *                                                                
             */

            //選第一層分類（hardcode）
            $scope.setCategoryId = function (selectedCategoryId) {
                logger.debug('setCategoryId:' + selectedCategoryId);
                $scope.categoryId = selectedCategoryId;

                //只顯示有支援的口味就好
                filterTaste = productCategoryMap[selectedCategoryId.toString()];
                console.log(filterTaste);
                var tasteString = '';
                for (var i = 0; i < filterTaste.length; i++) {
                    tasteString += '"' + filterTaste[i].name + '",'
                }
                logger.debug("tasteString:" + tasteString);

                //設定不能顯示的
                $scope.taste = []; //reset
                for (var i = 0; i < $scope.tasteDB.length; i++) {

                    var matchString = '"' + $scope.tasteDB[i].name + '"';
                    logger.debug('=============');
                    logger.debug('matchString:' + matchString);
                    var findPos = tasteString.indexOf(matchString);
                    logger.debug('pos:' + findPos);
                    if (findPos >= 0) {
                        logger.debug('比對成功taste:' + $scope.tasteDB[i].name);
                        $scope.taste.push($scope.tasteDB[i]);


                    }
                    logger.debug('=============');
                }
                logger.debug('第一層顯示下拉的結果');
                console.log($scope.taste);

            };

            //第二層分類改變口味
            //因為改變口味二個產品類型會有重覆的所以用js的前端資料庫比對，
            //可以減少建分類的數量
            $scope.changeTaste = function (taste) {
                logger.debug('changeTaste');
                console.log(taste);
                if (taste == null) {
                    Notification.info('請選擇口味');
                } else {
                    //找出可用的種類
                    for (var i = 0; i < filterTaste.length; i++) {
                        if (taste.name == filterTaste[i].name) {
                            // alert('wow');
                            filterCapacity = filterTaste[i].capacity;
                            break;
                        }
                    }
                    logger.debug('LOCAL DB口味可用的容量');
                    console.log(filterCapacity);

                    $scope.capacity = []; //reset
                    var addedCapacity = ''; //避免重覆容量已加過
                    blockUI.start();
                    CategoryService.getCategoriesOfType(
                        taste.id,
                        'taste',
                        function (data, status) {
                            blockUI.stop();
                            // $scope.capacity = data;
                            //需再過瀘
                            var oriCapacity = data;
                            logger.debug('SERVER DB 分類口味可用的容量');
                            console.log(oriCapacity);
                            for (var i = 0; i < oriCapacity.length; i++) {
                                var capacityTmp = oriCapacity[i];
                                logger.debug('capacity temp:' + capacityTmp.name);
                                for (var j = 0; j < filterCapacity.length; j++) {
                                    if (capacityTmp.name == filterCapacity[j]) {
                                        if (addedCapacity.indexOf(capacityTmp.name) < 0) {
                                            addedCapacity += '"' + capacityTmp.name + '"';
                                            $scope.capacity.push(capacityTmp);
                                            break;
                                        }

                                    }
                                }
                            }

                            if ($scope.capacity.length == 0) {
                                Notification.info('口味下無符合的容量');
                            }
                        },
                        function (data, status) {
                            blockUI.stop();
                        });
                }
            };

            //第三層改變容量=>過濾有的縣市
            $scope.changeCapacity = function (capacity) {
                logger.debug('changeCapacity');
                console.log(capacity);
                if (capacity == null) {
                    Notification.info('請選擇容量');
                } else {
                    $scope.cities = [];

                    blockUI.start();
                    CategoryService.getCategoriesOfType(
                        capacity.id,
                        'taste',
                        function (data, status) {
                            blockUI.stop();

                            var capacityCities = data;

                            logger.debug('SERVER DB 容量可用的縣市');
                            console.log(capacityCities);
                            console.log($scope.citiesDB);
                            for (var i = 0; i < capacityCities.length; i++) {
                                var cityTmp = capacityCities[i];
                                logger.debug('city temp:' + cityTmp.name);
                                for (var j = 0; j < $scope.citiesDB.length; j++) {
                                    logger.debug('cities db:' + $scope.citiesDB[j]);
                                    if (cityTmp.name == $scope.citiesDB[j]) {
                                        logger.debug('match city:' + cityTmp.name);
                                        $scope.cities.push(cityTmp.name);
                                        break;
                                    }
                                }
                            }

                            if ($scope.cities.length == 0) {
                                Notification.info('容量下無符合的區域');
                            }
                        },
                        function (data, status) {
                            blockUI.stop();
                        });
                }


            };

            /***
             *      /$$$$$$  /$$$$$$$$  /$$$$$$  /$$$$$$$   /$$$$$$  /$$   /$$
             *     /$$__  $$| $$_____/ /$$__  $$| $$__  $$ /$$__  $$| $$  | $$
             *    | $$  \__/| $$      | $$  \ $$| $$  \ $$| $$  \__/| $$  | $$
             *    |  $$$$$$ | $$$$$   | $$$$$$$$| $$$$$$$/| $$      | $$$$$$$$
             *     \____  $$| $$__/   | $$__  $$| $$__  $$| $$      | $$__  $$
             *     /$$  \ $$| $$      | $$  | $$| $$  \ $$| $$    $$| $$  | $$
             *    |  $$$$$$/| $$$$$$$$| $$  | $$| $$  | $$|  $$$$$$/| $$  | $$
             *     \______/ |________/|__/  |__/|__/  |__/ \______/ |__/  |__/
             *                                                                
             *                                                                
             *                                                                
             */
            $scope.search = function () {
                logger.debug('search');
                logger.debug('category Id:' + $scope.categoryId);
                logger.debug('==搜尋條件==');
                console.log($scope.selectedTaste);
                console.log($scope.selectedCapacity);
                console.log($scope.selectedCity);

                var gaResult = $scope.selectedTaste.name + '|' + 
                $scope.selectedCapacity.name + '|' +
                $scope.selectedCity;
                logger.debug('query:' + gaResult);

                $window.ga('send', {
                    hitType: 'event',
                    eventCategory: '通路查詢搜尋',
                    eventAction: '搜尋結果',
                    eventLabel: gaResult
                });

                var queryCity = '';


                blockUI.start("通路查詢中，請稍候...");


                if ($scope.selectedCity != null) {
                    if ($scope.selectedCity.indexOf('臺') > -1) {
                        $scope.selectedCity = $scope.selectedCity.replace('臺', '台');
                    }


                    queryCity = $scope.selectedCity;
                }

                CategoryService.getDealersByTasteIdAndCapacityIdAndAddress(
                    $scope.categoryId,
                    $scope.selectedTaste.id,
                    $scope.selectedCapacity.id,
                    queryCity,
                    function (data, status) {
                        blockUI.stop();
                        logger.debug('search ok');
                        $scope.dealers = data;


                    },
                    function (data, status) {
                        blockUI.stop();
                        $scope.dealers = [];
                        if (status == 404) {
                            Notification.info('沒有符合的通路商');
                        }
                    }
                );

            };

            /***
             *     /$$$$$$ /$$   /$$ /$$$$$$ /$$$$$$$$
             *    |_  $$_/| $$$ | $$|_  $$_/|__  $$__/
             *      | $$  | $$$$| $$  | $$     | $$   
             *      | $$  | $$ $$ $$  | $$     | $$   
             *      | $$  | $$  $$$$  | $$     | $$   
             *      | $$  | $$\  $$$  | $$     | $$   
             *     /$$$$$$| $$ \  $$ /$$$$$$   | $$   
             *    |______/|__/  \__/|______/   |__/   
             *                                        
             *                                        
             *                                        
             */

            $scope.init = function () {
                $scope.setCategoryId(6);
            };

            $scope.pageLoad = function () {
                if (!$scope.waitingUpdateUserData) {
                    $scope.init();
                } else {
                    logger.debug('waiting update user data event');
                }
            };

            $scope.pageLoad();


        }
    ]);